function drag(ev){
  ev.dataTransfer.setData("text", ev.target.id);
  ev.target.style.opacity = 0.5
}

function drop(ev){
  ev.preventDefault();
  ev.target.classList.remove('dragover')
  const targetNode = document.getElementById(ev.target.id)

  var data = ev.dataTransfer.getData("text");
  const oldNode = document.getElementById(data)
  oldNode.style.opacity = 1

  targetNode.parentNode.insertBefore(oldNode, targetNode)

  document.querySelectorAll('.priority-hidden').forEach((el, id) => {
    el.value = id
  })
}

function allowDrop(ev){
  ev.preventDefault();
}

function dragEnter(ev){
  ev.target.classList.add('dragover')
}

function dragLeave(ev){
  ev.target.classList.remove('dragover')
}

function dragEnd(ev){
  const oldNode = ev.target
  oldNode.style.opacity = 1
}

function makeSortable(el){
  el.draggable = true
  el.addEventListener('dragstart', drag)
  el.addEventListener('dragover', allowDrop)
  el.addEventListener('drop', drop)
  el.addEventListener('dragenter', dragEnter)
  el.addEventListener('dragleave', dragLeave)
  el.addEventListener('dragend', dragEnd)
}

window.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.draggable-building').forEach((buildingEl) => {
    makeSortable(buildingEl)
  })
})