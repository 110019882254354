window.addEventListener('turbolinks:load', () => {
  var buildings = []

  if(document.querySelector('#q_content_area_assignments_districts_district_number_eq')){
    document.querySelector('#q_content_area_assignments_districts_district_number_eq').addEventListener('change', (e) => {
      filteredBuildings(e.target.value)
    })
  }

  var showedValue = $('#q_content_area_assignments_buildings_id_eq').val()

  if( $('.person_search').length ){
    $.get('/api/buildings', function(data, status){
      buildings = data
      if (showedValue == '') {
        checkFilter()
      }
    })
  }
  $('.schools-drop').each(function(){
    $(this).hide();
    $(this).find('input').each(function(){
      if($(this).is(':checked')){
        $(this).closest('.schools-drop').addClass('active')
        $(this).closest('.schools-drop').show()
        $(this).closest('.district-drop').find('.district-expander').html('<i class="fa fa-minus" aria-hidden="true"></i>')
        $(this).closest('.region-district-list').addClass('active')
        $(this).closest('.region-district-list').show()
        $(this).closest('.region').find('.region-expander').html('<i class="fa fa-minus" aria-hidden="true"></i>')
      }
    })
  })

  function checkFilter(){
    var showedValue = document.querySelector('#q_content_area_assignments_buildings_id_eq').value
    if (showedValue == ''){
      showedValue = ''
    }

    filteredBuildings($('#q_content_area_assignments_districts_district_number_eq').val())
    $('#q_content_area_assignments_buildings_id_eq').val(showedValue)
  }

  function compare(a, b){
    if ( a < b ){
      return -1;
    }
    if ( a > b ){
      return 1;
    }
    return 0;
  }

  function compare( a, b ) {
    if ( a < b ){
      return -1;
    }
    if ( a > b ){
      return 1;
    }
    return 0;
  }


  function filteredBuildings(district){
    var filteredBuildings = buildings.filter(function(building){
      return building.district_number == district
    }).sort(function(building_a, building_b){
      return compare(building_a.name, building_b.name)
    })

    var buildingMessage = ''
    if (filteredBuildings.length > 0){
      buildingMessage = 'Search By Building'
    }else{
      buildingMessage = 'Select a District'
    }

    var options = '<option value="">'+buildingMessage+'</option>'
    filteredBuildings.forEach(function(building){
      options += '<option value="'+building.id+'">'+building.name+'</option>'
    })
    $('#q_content_area_assignments_buildings_id_eq').html(options)
    $('#q_content_area_assignments_buildings_id_eq').val('')
  }


  document.querySelectorAll('.directory__show-button').forEach(button => {
    button.addEventListener('click', () => {
      const directoryParent = button.closest('.directory')
      const hiddenAssignments = directoryParent.querySelector('.hidden-assignments')
      hiddenAssignments.classList.toggle('active')

      if(hiddenAssignments.style.maxHeight){
          hiddenAssignments.style.maxHeight = null
          hiddenAssignments.setAttribute('aria-hidden', true)
        } else {
          hiddenAssignments.style.maxHeight = hiddenAssignments.scrollHeight + 'px'
          hiddenAssignments.setAttribute('aria-hidden', false)
        }
    })
  })

  function hideAllActive(){
    document.querySelectorAll('div.active').forEach(div => {
      div.style.maxHeight = null
      div.classList.toggle('active')
    })
  }
})