function showSchools (thing) {
  let $schools = $(thing).closest('.district-drop').find('.schools-drop')
  if($schools.hasClass('active')){
    $schools.removeClass('active')
    $schools.slideUp();
    $(thing).html('<i class="fa fa-plus" aria-hidden="true"></i>')
  }else{
    $schools.addClass('active')
    $schools.slideDown();
    $(thing).html('<i class="fa fa-minus" aria-hidden="true"></i>')
  }
}

function selectAllSchools(thing){
  let $schools = $(thing).closest('.district-drop').find('.schools-drop')
  if($schools.hasClass('active') && $(thing).is(':checked')){
    $schools.find('input').each(function(){
      $(this).prop('checked', true)
    })
  } else {
    $schools.find('input').each(function(){
      $(this).prop('checked', false)
    })
  }
}


function showAssignment(thing){
  var $bucket = $(thing).parent().next()
  if ($bucket.hasClass('active')){
    $bucket.removeClass('active')
    $bucket.slideUp();
    $(thing).html('<i class="fa fa-plus" aria-hidden="true"></i>')
  }else{
    $bucket.addClass('active')
    $bucket.slideDown();
    $(thing).html('<i class="fa fa-minus" aria-hidden="true"></i>')
  }
}

function mirrorValueTo(thing, fieldName){
  var writeValue = thing.value
  console.log(writeValue, fieldName)

  document.querySelectorAll(fieldName).forEach(function(field){
    field.value = writeValue
  })
}

window.addEventListener('turbolinks:load', () => {
  window.showAssignment = showAssignment
  window.showSchools = showSchools
  window.mirrorValueTo = mirrorValueTo
  window.selectAllSchools = selectAllSchools
})