function getPeopleCsv(filename){
  downloadFromUrl('/admin/people.xlsx', filename)
}

function getDistrictAssignmentsExcel(filename){
  downloadFromUrl('/admin/districts.xlsx', filename)
}

function downloadFromUrl(url, filename){
  $('.loader').addClass('active')
  fetch(url)
    .then(res => {
      if(res.ok){
        return res.blob()
      }
    })
    .then(blob => {
      console.log('creating blob', blob)
      $('.loader').removeClass('active')

      const data = window.URL.createObjectURL(blob);
      let link = document.createElement('a');
      link.href = data;
      link.download = filename;
      document.body.appendChild(link)
      link.click();
      setTimeout(function(){
        document.body.removeChild(link)

        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      },100)
    })
    .catch(err => console.log(err))
}

window.addEventListener('turbolinks:load', () => {
  $('.activate-spinner').click(function(e){
    $('.loader').addClass('active')
  })

  window.getPeopleCsv = getPeopleCsv
  window.getDistrictAssignmentsExcel = getDistrictAssignmentsExcel
  window.downloadFromUrl = downloadFromUrl
})
